
import { Rive, Layout } from 'rive-js'
import GankLoading from '~/assets/animations/gank_loading_new.riv'

export default {
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      rive: null,
      isPlaying: false,
    }
  },
  mounted() {
    this.rive = new Rive({
      canvas: this.$refs.canvas,
      src: GankLoading,
      autoplay: true,
      layout: new Layout({
        fit: 'contain',
        alignment: 'center',
      }),
    })
  },
}
